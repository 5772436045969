import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import ImageText from "../elements/imagetext.tsx";
import Title from "../elements/title.tsx";
import Gap from "../elements/gap.tsx";
import AliceCarousel from "react-alice-carousel";
import Annotation from "../elements/annotation.tsx";
import TextLayout from "../elements/textlayout.tsx";
import PhotoGallery from "../elements/gallery.tsx";
import "react-alice-carousel/lib/alice-carousel.css";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <TextLayout mdxType="TextLayout">
  <h4>Vinylify</h4>
  <h5>
    Does reintroduction of retro tech attributes into smart devices improve the
    experience?
  </h5>
  <br />
  2021 <br />
  Keywords: design research, vinyl, slow technology, NFC
    </TextLayout>
    <PhotoGallery photos={[{
      src: "../../../vinylify/vinylify.jpeg",
      width: 4,
      height: 1
    }, {
      src: "../../../vinylify/layout.png",
      width: 3,
      height: 1
    }, {
      src: "../../../vinylify/Render.png",
      width: 3,
      height: 1
    }]} mdxType="PhotoGallery" />
    <TextLayout mdxType="TextLayout">
      <Title mdxType="Title">
  <b>Prototype</b>
      </Title>
      <img src="../../../vinylify/vinylify2.jpg" />
      <Annotation mdxType="Annotation">A phone cover as a portable vinyl player</Annotation>
      <img src="../../../vinylify/vinyl.jpeg" />
      <Annotation mdxType="Annotation">
  NFC embeded records for sharing, collecting and trading among users
      </Annotation>
      <Title mdxType="Title">
  <b>Research Methods - Diary Study</b>
      </Title>
      <img src="../../../vinylify/user.png" />
      <Annotation mdxType="Annotation">7 days diary study with participants</Annotation>
    </TextLayout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      